import React from "react";
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const DeviceCreate = (props) => (
  <MainCard>
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="deviceName" multiline fullWidth={true} />
        <ReferenceInput source="user_id" reference="users">
          <SelectInput optionText="email" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  </MainCard>
);

export default DeviceCreate;
