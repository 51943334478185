import bank from "./bank";
import users from "./users";
import analytics from "./analytics";
import transactions from "./transactions";
import campagne from "./campagne";
import devices from "./device";
import organisation from "./organisation";
export {
  bank,
  analytics,
  campagne,
  devices,
  users,
  organisation,
  transactions,
};
