import {
  BooleanField,
  DateField,
  EmailField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const UserShow = () => (
  <MainCard>
    <Show>
      <SimpleShowLayout>
        <DateField source="avatar" />
        <TextField source="role" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <EmailField source="email" />
        <BooleanField source="hasPassword" />
      </SimpleShowLayout>
    </Show>
  </MainCard>
);
export default UserShow;
