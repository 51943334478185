import { PasswordInput, SimpleForm } from "ra-ui-materialui";
import { useDataProvider } from "react-admin";
import React from "react";
import MainCard from "./components/MainCard";
import { useRedirect } from "react-admin";
import { useNotify } from "react-admin";

export const ProfilePassword = () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();

  const handleSave = (values) => {
    dataProvider("reset-password", "auth", values).then((data) => {
      if (data.success === "failed") {
        notify(data.error.message, { type: "failed" });
      } else {
        redirect("/login");
      }
    });
  };

  return (
    <MainCard title="Change password">
      <SimpleForm onSubmit={handleSave}>
        <PasswordInput source="newPassword" />
        <PasswordInput source="confirmNewPassword" />
      </SimpleForm>
    </MainCard>
  );
};
