import AnalyticsCreate from "./AnalyticsCreate";
import AnalyticsEdit from "./AnalyticsEdit";
import AnalyticsList from "./AnalyticsList";
import AnalyticsShow from "./AnalyticsShow";
import { LineChartOutlined } from "@ant-design/icons";
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  list: AnalyticsList,
  create: AnalyticsCreate,
  edit: AnalyticsEdit,
  icon: LineChartOutlined,
  show: AnalyticsShow,
};
