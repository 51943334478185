// material-ui
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
// project import
import PropTypes from "prop-types";
import { Loading } from "react-admin";
import { useEffect } from "react";
import { useDataProvider } from "react-admin";
import { useState } from "react";
import { useGetList } from "react-admin";
import MainCard from "../../pages/components/MainCard";
import LinearWithLabel from "../../pages/dashboard/components/Progress/LinearWithLabel";

const CampaigneListWidget = () => {
  const { data, total, isLoading, error } = useGetList("campagnes", {
    pagination: { page: 1, perPage: 10 },
    sort: { order: "DESC" },
  });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  return (
    <MainCard title="Campagne">
      <Grid item xs={12}>
        <Grid container spacing={1.25}>
          {data.map((record, index) => (
            <>
              <Grid item xs={6}>
                <Typography>{record.title}</Typography>
              </Grid>
              <Grid item xs={6}>
                <LinearWithLabel value={30} color="primary" />
              </Grid>
            </>
          ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};

CampaigneListWidget.propTypes = {
  tasks: PropTypes.array,
};

export default CampaigneListWidget;
