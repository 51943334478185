import React, { createContext, useState, useMemo } from "react";
import {
  TextInput,
  SimpleForm,
  useGetOne,
  Loading,
  useUpdate,
  useRedirect,
} from "react-admin";
import { useDataProvider } from "react-admin";
// import { SaveContextProvider } from "react-admin";
import { ImageInput } from "react-admin";
import { ImageField } from "react-admin";

import { useContext } from "react";
import { useNotify } from "react-admin";
import MainCard from "./components/MainCard";
const ProfileContext = createContext();

export const useProfile = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () =>
        setProfileVersion((currentVersion) => currentVersion + 1),
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const ProfileEdit = () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const storedProfile = JSON.parse(localStorage.getItem("user"));
  const [update] = useUpdate();

  const { data, isLoading } = useGetOne("users", {
    id: storedProfile._id,
  });
  const notify = useNotify();

  if (isLoading) return <Loading />;

  const handleSave = (values) => {
    update("users", { id: values._id, data: values }).then(() => {
      notify("Profile updated");
      redirect("/");
    });
  };

  return (
    <MainCard title="Personal Data">
      <SimpleForm record={data} onSubmit={handleSave}>
        <TextInput source="firstname" />
        <TextInput source="lastname" />
        <TextInput source="street" />
        <TextInput source="city" />
        <TextInput source="postcode" />
        <ImageInput
          source="data[avatar]"
          label="Related pictures"
          accept="image/*"
        >
          <ImageField source="src" title="name" />
        </ImageInput>
      </SimpleForm>
    </MainCard>
  );
};

export default ProfileEdit;
