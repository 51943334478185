import BankCreate from "./BankCreate";
// import BankEdit from "./BankEdit";
import BankList from "./BankList";
import BankShow from "./BankShow";
import { BankOutlined } from "@ant-design/icons";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  list: BankList,
  show: BankShow,
  icon: BankOutlined,
  create: BankCreate,
};
