import React from "react";
import { Admin, Resource } from "react-admin";
import * as fetchUtils from "./utils/fetch";
import { jwtDecode as decodeJwt } from "jwt-decode";
import { CustomRoutes } from "react-admin";
import { NotFound } from "react-admin";
import authProvider from "./dataProvider/authProvider";
import users from "./api/users";
import addUploadFeature from "./dataProvider/addUploadFeature";
import { Route } from "react-router";
import tokenProvider from "./dataProvider/tokenProvider";
import restProvider from "./restProvider";
import MyLayout from "./MyLayout";
import { createTheme } from "@mui/material/styles";
import { campagne, devices, bank, organisation, transactions } from "./api";

import {
  SignupPage,
  CompanyDataEdit,
  BankDataEdit,
  ForgotPasswordPage,
  ProfilePassword,
  MyLoginPage,
} from "./pages";

import UserDetails from "./pages/profile";
import DashboardDefault from "./pages/dashboard";
import Palette from "./themes/palette";
import Typography from "./themes/typography";
import CustomShadows from "./themes/shadows";
import componentsOverride from "./themes/overrides";
import VerifyCode from "./pages/verifyCode";
import addUserProfileOverrides from "./dataProvider/dataProvider";
// ==============================|| DEFAULT THEME - MAIN  ||============================== //

const pTheme = Palette("light", "default");

// eslint-disable-next-line react-hooks/exhaustive-deps
const themeTypography = Typography(`'Public Sans', sans-serif`);
const themeCustomShadows = CustomShadows(pTheme);

const themes = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1266,
      xl: 1536,
    },
  },
  direction: "ltr",
  palette: pTheme.palette,
  customShadows: themeCustomShadows,
  typography: themeTypography,
});
themes.components = componentsOverride(themes);

/**  End Theme */
const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = tokenProvider.getToken();

  if (token) {
    const decodedToken = decodeJwt(token);
    const { exp } = decodedToken;
    const now = new Date();
    if (now > (exp + 1) * 1000) {
      return tokenProvider.getRefreshedToken().then((gotFreshToken) => {
        if (gotFreshToken) {
          options.headers.set(
            "Authorization",
            `Bearer ${tokenProvider.getToken()}`
          );
        }
        return fetchUtils.fetchJson(url, options);
      });
    } else {
      options.headers.set("Authorization", `Bearer ${token}`);
      return fetchUtils.fetchJson(url, options);
    }
  }
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const API_URL = process.env.REACT_APP_API_URL || "";
const dataProvider = restProvider(`${API_URL}/api/v1`, httpClient);
const uploadCapableDataProvider = addUserProfileOverrides(
  addUploadFeature(dataProvider)
);

const App = () => {
  const permissions = authProvider.getPermissions();
  return (
    <Admin
      theme={themes}
      dashboard={DashboardDefault}
      title="Awesome App Admin"
      dataProvider={uploadCapableDataProvider}
      authProvider={authProvider}
      catchAll={NotFound}
      layout={MyLayout}
      loginPage={MyLoginPage}
    >
      <CustomRoutes>
        <Route exact path="/my-profile" element={<UserDetails />} />, ,
        <Route exact path="/my-company" element={<CompanyDataEdit />} />,
        <Route exact path="/my-bank" element={<BankDataEdit />} />,
        <Route exact path="/my-changepassword" element={<ProfilePassword />} />,
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route
          exact
          path="/signup"
          element={<SignupPage />}
          nolayout
          disableAuthentication
        />
        <Route exact path="/forgotpassword" element={<ForgotPasswordPage />} />,
        <Route exact path="/verify-code" element={<VerifyCode />} />,
      </CustomRoutes>
      {(permissions) => (
        <>
          <Resource name="campagnes" {...campagne} />
          <Resource name="transactions" {...transactions} />
          <Resource
            name="devices"
            {...devices}
            recordRepresentation="deviceName"
          />
          {permissions === "admin" && <Resource name="users" {...users} />}
          {permissions === "admin" && <Resource name="banks" {...bank} />}
          {permissions === "admin" && (
            <Resource name="organisations" {...organisation} />
          )}
        </>
      )}
    </Admin>
  );
};

export default App;
