import React from "react";
import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  TextInput,
  Filter,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="email" alwaysOn />
  </Filter>
);

const UserList = (props) => (
  <MainCard>
    <List {...props} filters={<UserFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="nameOfOrganisation" />

        <TextField source="legalform" />
        <ReferenceField source="user_id" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <TextField source="activityOfTheOrganization" />
        <TextField source="streetOfOrganisation" />
        <TextField source="cityOfOrganisation" />
        <TextField source="CountryOfOrganisation" />
        <TextField source="businessCategory" />
      </Datagrid>
    </List>
  </MainCard>
);

export default UserList;
