import React from "react";
import {
  CREATE,
  SimpleForm,
  TextInput,
  UPDATE,
  useDataProvider,
  useGetList,
  Loading,
  ReferenceInput,
  SelectInput,
  useRedirect,
} from "react-admin";

import { useNotify } from "react-admin";
import MainCard from "./components/MainCard";
export const BankDataEdit = () => {
  const notify = useNotify();
  const ressource = "banks";
  const storedProfile = JSON.parse(localStorage.getItem("user"));
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { data, isLoading } = useGetList(ressource, {
    filter: { user_id: storedProfile._id },
  });
  if (isLoading) return <Loading />;
  const handleSave = (values) => {
    if (data.length < 1) {
      dataProvider(CREATE, ressource, { data: values }).then((data) => {
        if (data.data.status === "sucess") {
          notify("Data was created", { type: data.success });
        } else if (data.data.status === "failed") {
          notify(data.error.message, { type: data.success });
        }
      });
    } else {
      dataProvider(UPDATE, ressource, { id: values._id, data: values }).then(
        (data, status) => {
          notify("Data was updated", { type: data.success });
        }
      );
    }
    redirect("/");
  };
  return (
    <MainCard title="Banking Data">
      <SimpleForm record={data ? data[0] : {}} onSubmit={handleSave}>
        <TextInput source="owner" multiline fullWidth={true} />
        <ReferenceInput
          source="user_id"
          reference="users"
          sort={{ field: "email", order: "ASC" }}
        >
          <SelectInput optionText="email" />
        </ReferenceInput>{" "}
        <TextInput source="IBAN" multiline fullWidth={true} />
        <TextInput source="SWIFT" multiline fullWidth={true} />
      </SimpleForm>
    </MainCard>
  );
};
