import { ApartmentOutlined } from "@ant-design/icons";
import OrganisationCreate from "./OrganisationCreate";
import OrganisationEdit from "./OrganisationEdit";
import OrganisationList from "./OrganisationList";
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  list: OrganisationList,
  create: OrganisationCreate,
  edit: OrganisationEdit,
  icon: ApartmentOutlined,
};
