import PropTypes from "prop-types";

// material-ui
import { Grid, Stack, Typography } from "@mui/material";
import MainCard from "../../components/MainCard";

// project imports

// ==============================|| REPORT CARD ||============================== //

const ReportCard = ({ primary, secondary, iconPrimary, color }) => {
  const IconPrimary = iconPrimary;
  const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Stack spacing={1}>
          <Typography variant="h4">{primary}</Typography>
          <Typography variant="body1" color="secondary">
            {secondary}
          </Typography>
        </Stack>
      </Grid>
      <Grid item>
        <Typography variant="h2" style={{ color }}>
          {primaryIcon}
        </Typography>
      </Grid>
    </Grid>
  );
};

ReportCard.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  iconPrimary: PropTypes.object,
  color: PropTypes.string,
};

export default ReportCard;
