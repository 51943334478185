import UserList from "./UserList";
import UserEdit from "./UserEdit";
import UserCreate from "./UserCreate";
import UserShow from "./UserShow";
import { UserOutlined } from "@ant-design/icons";
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
  show: UserShow,
  icon: UserOutlined,
};
