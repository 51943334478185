import TransactionCreate from "./TransactionsCreate";
import TransactionEdit from "./TransactionsEdit";
import TransactionList from "./TransactionsList";
import TransactionShow from "./TransactionsShow";
import { LineChartOutlined } from "@ant-design/icons";
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  list: TransactionList,
  // create: TransactionCreate,
  // edit: TransactionEdit,
  icon: LineChartOutlined,
  show: TransactionShow,
};
