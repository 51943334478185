import PropTypes from "prop-types";

// material-ui
import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Loading, useDataProvider } from "react-admin";

// project imports

// ==============================|| REPORT CARD ||============================== //

const RevenueWidget = ({ primary, secondary, iconPrimary, color }) => {
  const IconPrimary = iconPrimary;
  const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;
  const [data, setData] = useState("");

  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dataProvider("INFO", "transactions").then((data) => {
      const val = data.data;
 
      if (typeof val === "object") {
        switch (primary) {
          case "revenueAll":
            if (val.revenue180[0]){
            	setData(val.revenue180[0].total);
            }
            break;
          case "revenue30":
            setData(val.revenue30[0] ? val.revenue30[0].total : 0);
            break;
          case "revenue1":
            setData(val.revenue1[0] ? val.revenue1[0].total : 0);
            break;
          case "revenue180":
     
            
            setLoading(false);
            break;

          default:
            setData(0);
            setLoading(false);

            break;
        }
        setLoading(false);
      }
      
    });
  }, []);
  if (loading) return <Loading />;
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Stack spacing={1}>
          <Typography variant="h4">
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(data)}
          </Typography>
          <Typography variant="body1" color="secondary">
            {secondary}
          </Typography>
        </Stack>
      </Grid>
      <Grid item>
        <Typography variant="h2" style={{ color }}>
          {primaryIcon}
        </Typography>
      </Grid>
    </Grid>
  );
};

RevenueWidget.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  iconPrimary: PropTypes.object,
  color: PropTypes.string,
};

export default RevenueWidget;
