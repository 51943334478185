//TODO: remove class

// A function decorating a dataProvider for handling user profiles
const API_URL = process.env.REACT_APP_API_URL || "";

const addUserProfileOverrides = (dataProvider) => (verb, resource, params) => {
  // const token = localStorage.getItem("token");
  // // if (verb === "my") {
  //   delete params.email;
  //   delete params.isPremium;
  //   delete params.role;
  //   // const test = uploadFile(params, "image", "avatar");
  //   console.log(JSON.stringify(params));
  //   console.log(token);
  //   const requestdata = params;
  //   delete requestdata.id;
  //   delete requestdata.data.__v;
  //   delete requestdata.data.createdAt;
  //   delete requestdata.data.updatedAt;
  //   delete requestdata.data.hasPassword;
  //   delete requestdata.data.data;
  //   delete requestdata.data.role;
  //   delete requestdata.data.email;
  //   delete requestdata.data._id;
  //   return fetch(`${API_URL}/api/v1/users/me`, {
  //     method: "PUT",
  //     body: JSON.stringify(requestdata),
  //   }).then((response) => {
  //     return Promise.resolve(response.json());
  //   });
  // }

  if (resource === "auth") {
    const body = JSON.stringify(params);
    const token = localStorage.getItem("token");
    const request = new Request(`${API_URL}/api/v1/auth/` + verb, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    return fetch(new Request(request))
      .then((response) => {
        return Promise.resolve(response.json());
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
  return dataProvider(verb, resource, params);

  // Fallback to the dataProvider default handling
  // };
  // const uploadFile = async (params, field, name) => {
  //   return new Promise((resolve) => {
  //     if (params.data[name] && params.data[name].rawFile instanceof File) {
  //       const formData = new FormData();
  //       formData.append(field, params.data[name].rawFile);
  //       const token = localStorage.getItem("token");

  //       fetch("/api/v1/uploads", {
  //         method: "post",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: formData,
  //       })
  //         .then((response) => response.json())
  //         .then((image) => {
  //           const tmp = {
  //             ...params,
  //             data: {
  //               ...params.data,
  //               [name]: {
  //                 src: image.data.url,
  //                 title: image.data.title,
  //               },
  //             },
  //           };
  //           return resolve(tmp);
  //         });
  //     } else {
  //       return resolve(params);
  //     }
  //   });
};

export default addUserProfileOverrides;
