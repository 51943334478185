import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const CampagneShow = () => (
  <MainCard>
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="device_ids" reference="devices">
          <TextField source="devideName" />
        </ReferenceField>
        <ReferenceField source="user_ids" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <TextField source="title" />
        <TextField source="description" />
        <TextField source="tier1" />
        <TextField source="tier2" />
        <TextField source="tier3" />
        <TextField source="target" />
        <DateField source="imageUrl" />
      </SimpleShowLayout>
    </Show>
  </MainCard>
);

export default CampagneShow;
