import {
  SimpleForm,
  TextInput,
  useDataProvider,
  CREATE,
  UPDATE,
  useGetList,
  Loading,
  useNotify,
  useRedirect,
} from "react-admin";
import React from "react";
import MainCard from "./components/MainCard";

export const CompanyDataEdit = () => {
  const ressource = "organisations";
  const storedProfile = JSON.parse(localStorage.getItem("user"));
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const { data, isLoading } = useGetList(ressource, {
    filter: { user_id: storedProfile._id },
  });

  if (isLoading) return <Loading />;
  const handleSave = (values) => {
    values.customerId = storedProfile._id;
    if (data.length < 1) {
      dataProvider(CREATE, ressource, { data: values }).then((data) => {
        notify("Data was created", { type: "sucess" });
      });
    } else {
      console.log(values);
      dataProvider(UPDATE, ressource, { id: values._id, data: values }).then(
        (data) => {
          notify("Data was updated", { type: "sucess" });
        }
      );
    }
    redirect("/");
  };

  return (
    <MainCard title="Company Data">
      <SimpleForm record={data ? data[0] : {}} onSubmit={handleSave}>
        <TextInput source="legalform" multiline fullWidth={true} />
        <TextInput source="customerId" multiline fullWidth={true} />
        <TextInput source="nameOfOrganisation" multiline fullWidth={true} />
        <TextInput source="businessCategory" multiline fullWidth={true} />
        <TextInput
          source="activityOfTheOrganization"
          multiline
          fullWidth={true}
        />
        <TextInput source="streetOfOrganisation" multiline fullWidth={true} />
        <TextInput source="cityOfOrganisation" multiline fullWidth={true} />
        <TextInput source="CountryOfOrganisation" multiline fullWidth={true} />
      </SimpleForm>
    </MainCard>
  );
};
