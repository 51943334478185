import {
  ApartmentOutlined,
  BankOutlined,
  BlockOutlined,
  LogoutOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import React from "react";
import { UserMenu, MenuItemLink, Logout } from "react-admin";

const MyUserMenu = (props) => {
  return (
    <UserMenu {...props}>
      <MenuItemLink
        key={1}
        to="/my-profile"
        primaryText="My Profile"
        leftIcon={<ProfileOutlined />}
      />
      <MenuItemLink
        key={2}
        to="/my-company"
        primaryText="Company Data"
        leftIcon={<ApartmentOutlined />}
      />
      <MenuItemLink
        key={3}
        to="/my-bank"
        primaryText="Banking Data"
        leftIcon={<BankOutlined />}
      />
      <MenuItemLink
        key={4}
        to="/my-changepassword"
        primaryText="Change Password"
        leftIcon={<BlockOutlined />}
      />
      <Logout icon={<LogoutOutlined />}></Logout>
    </UserMenu>
  );
};

export default MyUserMenu;
