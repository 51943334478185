import React from "react";
import { SimpleForm, TextInput, useNotify, useRedirect } from "react-admin";
import { useDataProvider } from "react-admin";
import { Card, Box, Typography } from "@mui/material";

export const ForgotPasswordPage = () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const handleSave = (values) => {
    dataProvider("forgot-password", "auth", values).then((data) => {
      if (data.success === "failed") {
        notify(data.error.message, { type: "failed" });
      } else {
        redirect("/verify-code");
      }
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "url(https://source.unsplash.com/random/1600x900)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Card sx={{ minWidth: 300, marginTop: "6em" }}>
        <SimpleForm onSubmit={handleSave}>
          <Typography variant="h3" component="h1">
            Reset password
          </Typography>
          <Typography>
            Please enter your SumUp email address. We'll send you instructions
            on how to reset your password.
          </Typography>
          <TextInput source="email" fullWidth />
        </SimpleForm>
      </Card>
    </Box>
  );
};

export default ForgotPasswordPage;
