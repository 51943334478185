import React from "react";
import {
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const AnalyticsEdit = (props) => (
  <MainCard>
    <Edit {...props}>
      <SimpleForm redirect="list">
        <TextInput disabled source="id" />
        <TextInput source="type" />
        <TextInput source="location" />
        <TextInput source="transactionId" />
        <NumberInput source="amount" />
        <ReferenceInput source="campagneId" reference="campagnes">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <DateInput source="trackingDate" />
        <ReferenceInput source="deviceId" reference="devices">
          <SelectInput source="devideName" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  </MainCard>
);

export default AnalyticsEdit;
