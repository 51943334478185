import React from "react";
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  TextInput,
  Filter,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const AnalyticsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="email" alwaysOn />
  </Filter>
);

const AnalyticsList = (props) => (
  <MainCard>
    <List {...props} filters={<AnalyticsFilter />}>
      <Datagrid rowClick="show">
        <TextField source="id" />

        <TextField source="type" />
        <TextField source="location" />
        <TextField source="transactionId" />
        <NumberField source="amount" />
        <ReferenceField source="campagneId" reference="campagnes">
          <TextField source="title" />
        </ReferenceField>
        <DateField source="trackingDate" />
        <ReferenceField source="deviceId" reference="devices">
          <TextField source="devideName" />
        </ReferenceField>
      </Datagrid>
    </List>
  </MainCard>
);

export default AnalyticsList;
