import React from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  ReferenceField,
  ReferenceArrayField,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const CampagneFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="email" alwaysOn />
  </Filter>
);

const CampagneList = () => (
  <MainCard>
    <List filters={<CampagneFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceArrayField
          tag="deviceName"
          source="device_ids"
          reference="devices"
        />

        <ReferenceField source="user_ids" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <TextField source="title" />
        <TextField source="description" />
        <TextField source="tier1" />
        <TextField source="tier2" />
        <TextField source="tier3" />
        <TextField source="target" />
      </Datagrid>
    </List>
  </MainCard>
);

export default CampagneList;
