import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const DeviceShow = () => (
  <MainCard>
    <Show>
      <SimpleShowLayout>
        <TextField source="pairingCode" />
      </SimpleShowLayout>
    </Show>
  </MainCard>
);

export default DeviceShow;
