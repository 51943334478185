import { BoxPlotFilled } from "@ant-design/icons";
import CampagneCreate from "./CampagneCreate";
import CampagneEdit from "./CampagneEdit";
import CampagneList from "./CampagneList";
import CampagneShow from "./CampaigneShow";
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  list: CampagneList,
  create: CampagneCreate,
  edit: CampagneEdit,
  icon: BoxPlotFilled,
  show: CampagneShow,
};
