import React from "react";
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  TextInput,
  Filter,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const TransactionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="email" alwaysOn />
  </Filter>
);

const TransactionList = (props) => (
  <MainCard>
    <List
      {...props}
      filters={<TransactionFilter />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />

        <TextField source="status" />
        <TextField source="location" />
        <TextField source="transactionId" />
        <NumberField source="amount" />
        <ReferenceField source="campagneId" reference="campagnes">
          <TextField source="title" />
        </ReferenceField>
        <DateField source="transactionDate" showTime locales="de-DE" />
        <ReferenceField source="device_ids" reference="devices">
          <TextField source="deviceName" />
        </ReferenceField>
      </Datagrid>
    </List>
  </MainCard>
);

export default TransactionList;
