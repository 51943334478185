import { useState } from "react";

// material-ui
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  LinearProgress,
} from "@mui/material";

import {
  AimOutlined,
  BarChartOutlined,
  CalendarOutlined,
  ContactsOutlined,
  DownloadOutlined,
  EyeOutlined,
  FacebookOutlined,
  FileTextOutlined,
  FileProtectOutlined,
  FieldTimeOutlined,
  LinkedinOutlined,
  RedditOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

// project import
import OrdersTable from "./OrdersTable";
import IncomeAreaChart from "./IncomeAreaChart";
import MonthlyBarChart from "./MonthlyBarChart";
import ReportAreaChart from "./ReportAreaChart";
import SalesColumnChart from "./SalesColumnChart";
import MainCard from "../components/MainCard";

// assets

import { useDataProvider, Loading, useTheme } from "react-admin";
import { useEffect } from "react";
import Palette from "../../themes/palette";
import ReportCard from "./components/ReportCard";
import UserCountCard from "./components/UserCountCard";
import LastTransactionWidget from "./components/LatestTransactionWidget";
import RevenueWidget from "./components/RevenueWidget";
import DeviceWidget from "../../api/device/DeviceWidget";
import CampaigneListWidget from "../../api/campagne/CampaigneListWidget";

const status = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "month",
    label: "This Month",
  },
  {
    value: "year",
    label: "This Year",
  },
];
// ==============================|| DASHBOARD - DEFAULT ||============================== //

const DashboardDefault = () => {
  const [value, setValue] = useState("today");
  const [slot, setSlot] = useState("week");
  const [data, setData] = useState("");

  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dataProvider("INFO", "transactions").then((data) => {
      setData(data.data);
      setLoading(false);
    });
  }, []);
  if (loading) return <Loading />;

  var formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  const theme = Palette("light", "default");

  return (
    <Grid container spacing={3}>
      {/*  
      <Grid item xs={12} md={6} lg={8}>
        <ReaderCard />
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <MainCard>
          <Stack spacing={3}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Stack>
                  <Typography variant="h5" noWrap>
                    Help & Support Chat
                  </Typography>
                  <Typography variant="caption" color="secondary" noWrap>
                    Typical replay within 5 min
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <AvatarGroup
                  sx={{ "& .MuiAvatar-root": { width: 32, height: 32 } }}
                >
                  <Avatar alt="Remy Sharp" src={avatar1} />
                  <Avatar alt="Travis Howard" src={avatar2} />
                  <Avatar alt="Cindy Baker" src={avatar3} />
                  <Avatar alt="Agnes Walker" src={avatar4} />
                </AvatarGroup>
              </Grid>
            </Grid>
            <Button
              size="small"
              variant="contained"
              sx={{ textTransform: "capitalize" }}
            >
              Need Help?
            </Button>
          </Stack>
        </MainCard>
      </Grid> */}
      <Grid item xs={12} md={8} lg={4}>
        <MainCard title="Revenue" contentSX={{ p: 2.25 }}>
          <RevenueWidget
            primary="revenueAll"
            secondary="All"
            iconPrimary={FileTextOutlined}
          />
        </MainCard>
      </Grid>
      <Grid item xs={12} md={8} lg={4}>
        <MainCard title="Revenue" contentSX={{ p: 2.25 }}>
          <RevenueWidget
            primary="revenue30"
            secondary="Last 28 days"
            iconPrimary={FileTextOutlined}
          />
        </MainCard>
      </Grid>
      <Grid item xs={12} md={8} lg={4}>
        <MainCard title="Revenue" contentSX={{ p: 2.25 }}>
          <RevenueWidget
            primary="revenue1"
            secondary=" Today"
            iconPrimary={FileTextOutlined}
          />
        </MainCard>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <MainCard title="Pay out">
          <ReportCard
            primary="999+"
            secondary="Transaction"
            iconPrimary={FileTextOutlined}
          />
          {/* <UsersCardChart /> */}
        </MainCard>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <MainCard title="Transactions">
          <ReportCard
            primary="1"
            secondary="Devices"
            iconPrimary={FileTextOutlined}
          />
          {/* <OrdersCardChart /> */}
        </MainCard>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <DeviceWidget />
      </Grid>
      {/* <Grid item xs={12} md={6} lg={8}>
        <MainCard title="Revenue by Day">
          <IncomeAreaChart slot={slot} />
        </MainCard>
      </Grid> */}
      <Grid item xs={12} md={6} lg={6}>
        <CampaigneListWidget />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <MainCard title="Campaigne---">
          {/* <MonthlyBarChart /> */}
          <LastTransactionWidget />
        </MainCard>
        <MainCard>
          <UserCountCard
            primary="Revenue per Device"
            secondary="1,658"
            iconPrimary={ContactsOutlined}
            color={theme.palette.success.light}
          />
        </MainCard>
        <MainCard>
          <UserCountCard
            primary="Revenue per Transaction"
            secondary="1,658"
            iconPrimary={ContactsOutlined}
            color={theme.palette.success.light}
          />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default DashboardDefault;
