import React from "react";
import {
  TextField,
  Show,
  Button,
  SimpleShowLayout,
  ReferenceField,
  DateField,
} from "react-admin";
import { useRecordContext } from "react-admin";
import MainCard from "../../pages/components/MainCard";
import MapComponent from "../../pages/dashboard/components/mapComponent";

const PDFLink = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <a href={`/#/receipt/${record.id}`} target="_bank">
      Receipt
    </a>
  );
};

const Map = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <MapComponent data={record.location.split(",")} />;
};

const TransactionShow = (props) => (
  <MainCard>
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="status" />
        <DateField source="transactionDate" />
        <TextField source="location" />
        <TextField source="transactionId" />
        <TextField source="amount" />
        <ReferenceField source="campagneId" reference="campagnes">
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField source="user_ids" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <PDFLink />
      </SimpleShowLayout>
      <Map />
    </Show>
  </MainCard>
);

export default TransactionShow;
