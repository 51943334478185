import { FileTextOutlined } from "@ant-design/icons";
import MainCard from "../../pages/components/MainCard";
import ReportCard from "../../pages/dashboard/components/ReportCard";
import { Loading, useGetList } from "react-admin";

const DeviceWidget = () => {
  const { data, total, isLoading, error, refetch } = useGetList("devices", {
    page: 1,
    perPage: 10,
    filter: { isPaired: true },
  });
  if (isLoading) {
    return <Loading />;
  }
  return (
    <MainCard title="Paired Devices">
      <ReportCard
        primary={total}
        secondary="Devices"
        iconPrimary={FileTextOutlined}
      />
    </MainCard>
  );
};
export default DeviceWidget;
