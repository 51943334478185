import React from "react";
import {
  PasswordInput,
  SimpleForm,
  TextInput,
  useDataProvider,
} from "react-admin";
import { useRedirect } from "react-admin";
import { useNotify } from "react-admin";
import { Card, Box, Typography } from "@mui/material";
import MainCard from "./components/MainCard";

export const SignupPage = () => {
  const redirect = useRedirect();
  const notify = useNotify();

  const dataProvider = useDataProvider();
  const handleSave = (values) => {
    dataProvider("signup", "auth", values).then((data) => {
      if (data.success === "failed") {
        notify(data.error.message, { type: "failed" });
      } else {
        // notify("User was created", { type: "failed" });
        redirect("/");
      }
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "url(https://source.unsplash.com/random/1600x900)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <MainCard sx={{ Width: 300, marginTop: "6em" }}>
        <Typography variant="h5" color="textPrimary">
          Sign up Page
        </Typography>
        <Typography variant="body" color="textPrimary">
          Please enter your SumUp email address. We'll send you instructions on
          how to reset your password.
        </Typography>
        <SimpleForm redirect="list" onSubmit={handleSave}>
          <TextInput source="firstname" fullWidth />
          <TextInput source="lastname" fullWidth />
          <TextInput source="email" fullWidth />
          <PasswordInput source="password" fullWidth />
        </SimpleForm>
      </MainCard>
    </Box>
  );
};

export default SignupPage;
