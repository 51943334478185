import { jwtDecode as decodeJwt } from "jwt-decode";
const tokenProvider = () => {
  const getRefreshedToken = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const refreshEndpoint = `${API_URL}/api/v1/auth/refresh-token`;

    const request = new Request(refreshEndpoint, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify({ refreshToken: getLocalRefreshToken() }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status !== 200) {
          removeToken();
          return false;
        }
        return response.json();
      })
      .then(({ data }) => {
        if (data) {
          const { token, refreshToken } = data;
          setToken(token);
          setRefreshToken(refreshToken);
          return true;
        }
        return false;
      });
  };

  const setToken = (token) => {
    const decodedToken = decodeJwt(token);
    localStorage.setItem("token", token);
    return true;
  };
  const setRefreshToken = (token) => {
    const decodedToken = decodeJwt(token);
    localStorage.setItem("refreshToken", token);
    return true;
  };

  const removeToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    return true;
  };

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const getLocalRefreshToken = () => {
    return localStorage.getItem("refreshToken");
  };

  return {
    getRefreshedToken,
    getLocalRefreshToken,
    setRefreshToken,
    getToken,
    setToken,
    removeToken,
  };
};

export default tokenProvider();
