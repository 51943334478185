import React from "react";

import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const OrganisationEdit = (props) => (
  <MainCard>
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <ReferenceInput source="user_id" reference="users">
          <SelectInput optionText="email" />
        </ReferenceInput>
        <TextInput source="nameOfOrganisation" />
        <TextInput source="legalform" />
        <TextInput source="businessCategory" />
        <TextInput source="activityOfTheOrganization" />
        <TextInput source="streetOfOrganisation" />
        <TextInput source="cityOfOrganisation" />
        <TextInput source="CountryOfOrganisation" />
      </SimpleForm>
    </Edit>
  </MainCard>
);

export default OrganisationEdit;
