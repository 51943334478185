import React from "react";
import {
  Edit,
  DateInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ImageField,
  ImageInput,
  usePermissions,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";
import { Grid } from "@mui/material";
const CampagneEdit = (props) => {
  const { permissions } = usePermissions();
  const user_id = localStorage.getItem("user_id");

  return (
    <MainCard title="New Campaigne" sx={{ Width: "6em" }}>
      <Edit {...props}>
        <SimpleForm redirect="list">
          <Grid container spacing={3}>
            {permissions === "admin" && (
              <Grid item xs={12}>
                <ReferenceInput
                  fullWidth={true}
                  source="user_ids"
                  reference="users"
                  sort={{ field: "email", order: "ASC" }}
                >
                  <SelectInput optionText="email" fullWidth />
                </ReferenceInput>{" "}
              </Grid>
            )}
            <Grid item xs={12}>
              <TextInput source="title" multiline fullWidth={true} />
            </Grid>
            <Grid item xs={12}>
              <TextInput source="description" multiline fullWidth={true} />
            </Grid>
            <Grid item xs={6}>
              <DateInput source="campagneStart" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <DateInput source="campagneEnd" fullWidth={true} />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="tier1" multiline fullWidth={true} />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="tier2" multiline fullWidth={true} />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="tier3" multiline fullWidth={true} />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="target" multiline fullWidth={true} />
            </Grid>
            <Grid item xs={12}>
              <ReferenceArrayInput
                fullWidth
                source="device_ids"
                reference="devices"
                filter={{ isPaired: true, user_ids: { user_id } }}
                sort={{ field: "deviceName", order: "ASC" }}
              >
                <SelectArrayInput optionText="deviceName" fullWidth />
              </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12}>
              <ImageInput
                source="pictures"
                label="Related pictures"
                accept="image/*"
                placeholder={<p>Drop your file here</p>}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    </MainCard>
  );
};

export default CampagneEdit;
