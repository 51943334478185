import SignupPage from "./signuppage";
import { CompanyDataEdit } from "./companyData_Page";
import { BankDataEdit } from "./bankData_Page";
import ForgotPasswordPage from "./forgetpassword_page";
import { ProfilePassword } from "./profile_password";
// import { UserDetails } from "./profile";
import MyLoginPage from "./loginpage";
export {
  SignupPage,
  CompanyDataEdit,
  BankDataEdit,
  ForgotPasswordPage,
  // UserDetails,
  ProfilePassword,
  MyLoginPage,
};
