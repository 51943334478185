import React from "react";
import {
  Create,
  DateInput,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  ImageField,
  ReferenceArrayInput,
  SelectArrayInput,
  usePermissions,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

import { Grid } from "@mui/material";
import authProvider from "../../dataProvider/authProvider";

const CampagneCreate = (props) => {
  const { id, email, role } = JSON.parse(localStorage.getItem("user"));
  const { permissions } = usePermissions();

  return (
    <MainCard title="New Campaigne" sx={{ Width: "6em" }}>
      <Create {...props}>
        <SimpleForm redirect="list">
          <Grid container spacing={3}>
            {permissions === "admin" && (
              <Grid item xs={12}>
                <ReferenceInput
                  fullWidth={true}
                  source="user_ids"
                  reference="users"
                  sort={{ field: "email", order: "ASC" }}
                >
                  <SelectInput optionText="email" fullWidth />
                </ReferenceInput>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextInput source="title" multiline fullWidth={true} />
            </Grid>
            <Grid item xs={12}>
              <TextInput source="description" multiline fullWidth={true} />
            </Grid>
            <Grid item xs={6}>
              <DateInput source="campagneStart" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <DateInput source="campagneEnd" fullWidth={true} />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="tier1" multiline fullWidth={true} />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="tier2" multiline fullWidth={true} />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="tier3" multiline fullWidth={true} />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="target" multiline fullWidth={true} />
            </Grid>
            <Grid item xs={12}>
              <ReferenceArrayInput
                fullWidth
                source="device_ids"
                reference="devices"
                filter={{ isPaired: true, user_ids: id }}
                sort={{ field: "deviceName", order: "ASC" }}
              >
                <SelectArrayInput optionText="deviceName" fullWidth />
              </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12}>
              <ImageInput
                source="pictures"
                label="Related pictures"
                accept="image/*"
                placeholder={<p>Drop your file here</p>}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </MainCard>
  );
};

export default CampagneCreate;
