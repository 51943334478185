import React from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  ReferenceField,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="email" alwaysOn />
  </Filter>
);

const BankList = (props) => (
  <MainCard>
    <List {...props} filters={<UserFilter />}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ReferenceField source="user_id" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <TextField source="owner" />
        <TextField source="IBAN" />
        <TextField source="SWIFT" />
      </Datagrid>
    </List>
  </MainCard>
);

export default BankList;
