export default function RaSidebar(theme) {
  return {
    RaLayout: {
      styleOverrides: {
        root: {
          "& .RaLayout-content": {
            marginTop: "30px",
          },
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowX: "hidden",
          backgroundColor: "white",
          borderRight: `1px solid ${theme.palette.divider}`,
          boxShadow: "none",
          "& .RaDatagrid-headerCell": {
            boxShadow:
              "1px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          width: "300px",
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowX: "hidden",

          boxShadow: "none",
          "& .RaDatagrid-headerCell": {},
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.divider}`,
        },
      },
    },

    RaDatagrid: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary,
          "& .RaDatagrid-headerCell": {
            backgroundColor: theme.palette.secondary.lighter,
          },
        },
      },
    },
    RaAppBar: {
      styleOverrides: {
        root: {
          height: "60px",
          borderBottom: `1px solid ${theme.palette.divider}`,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowX: "hidden",
          color: "black",
          backgroundColor: "white",
          boxShadow: "none",
          "& .RaDatagrid-headerCell": {
            backgroundColor: theme.palette.primary,
          },
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          // invisible border when not active, to avoid position flashs
          padding: "15px",
          color: theme.palette.secondary.A300,
          "&.RaMenuItemLink-active": {
            backgroundColor: theme.palette.primary.lighter,
            borderRight: "2px solid " + theme.palette.primary.light,
          },
          "&.RaMenuItemLink:hover": {
            backgroundColor: theme.palette.primary.main,
            borderRight: "2px solid " + theme.palette.primary.light,
          },
          "&.RaMenuItemLink": {
            backgroundColor: theme.palette.primary.main,
            borderRight: "5px solid " + theme.palette.primary.light,
            fontSize: "12px",
          },
          "& .RaMenuItemLink-icon": {
            fontSize: "12px",
            color: theme.palette.secondary.A300,
          },
        },
      },
    },
  };
}
