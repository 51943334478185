import React from "react";
import {
  List,
  TextField,
  TextInput,
  Filter,
  BooleanField,
  ReferenceField,
  TopToolbar,
  ExportButton,
  DatagridConfigurable,
  Button,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const DeviceFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="email" alwaysOn />
  </Filter>
);

const DeivceList = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const Empty = () => {
    return (
      <Button size="small" color="primary" onClick={() => handleSave("main")}>
        Paring Code
      </Button>
    );
  };

  const PostFilterButton = () => {
    return (
      <Button size="small" color="primary" onClick={() => handleSave("main")}>
        Paring Code
      </Button>
    );
  };
  const ListActions = () => (
    <TopToolbar>
      <ExportButton />
      <PostFilterButton />
    </TopToolbar>
  );

  const handleSave = (values) => {
    dataProvider("GETCODE", "devices").then((data) => {
      notify("Code has been generated successfully");
      redirect("show", "devices", data.data._id);
    });
  };
  return (
    <MainCard>
      <List
        {...props}
        actions={<ListActions />}
        filters={<DeviceFilter />}
        empty={<Empty />}
      >
        <DatagridConfigurable rowClick="show">
          <TextField source="id" />
          <TextField source="pairingCode" />
          <TextField source="deviceName" />
          <ReferenceField source="user_ids" reference="users">
            <TextField source="email" />
          </ReferenceField>
          <BooleanField source="isPaired" />
        </DatagridConfigurable>
      </List>
    </MainCard>
  );
};

export default DeivceList;
