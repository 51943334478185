import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const BankCreate = (props) => (
  <MainCard>
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="owner" multiline fullWidth={true} />

        <ReferenceInput
          source="user_id"
          reference="users"
          sort={{ field: "email", order: "ASC" }}
        >
          <SelectInput optionText="email" />
        </ReferenceInput>
        <TextInput source="IBAN" multiline fullWidth={true} />
        <TextInput source="SWIFT" multiline fullWidth={true} />
      </SimpleForm>
    </Create>
  </MainCard>
);

export default BankCreate;
