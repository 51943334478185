import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

import { validateEmail } from "../validates";

const UserTitle = ({ record }) => {
  return <span>User {record ? record.username : ""}</span>;
};

const role = [
  { id: "admin", name: "Admin" },
  { id: "user", name: "User" },
];

const UserEdit = (props) => (
  <MainCard>
    <Edit {...props} title={<UserTitle />}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <SelectInput source="role" choices={role} />
        <TextInput source="firstname" />
        <TextInput source="lastname" />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="avatar" />
        <BooleanInput source="hasPassword" />
      </SimpleForm>
    </Edit>
  </MainCard>
);

export default UserEdit;
