import { CarOutlined } from "@ant-design/icons";
import DeviceCreate from "./DeviceCreate";
import DeviceEdit from "./DeviceEdit";
import DeviceList from "./DeviceList";
import DeviceShow from "./DeviceShow";
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  list: DeviceList,
  create: DeviceCreate,
  icon: CarOutlined,
  edit: DeviceEdit,
  show: DeviceShow,
};
