import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  List,
  TextField,
  Filter,
  TextInput,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";
const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="email" alwaysOn />
  </Filter>
);
export const UserList = () => (
  <MainCard>
    <List filters={<UserFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <DateField source="avatar" />
        <EmailField source="email" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <TextField source="role" />
        <BooleanField source="hasPassword" />
      </Datagrid>
    </List>
  </MainCard>
);
export default UserList;
