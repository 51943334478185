import { jwtDecode as decodeJwt } from "jwt-decode";
import tokenProvider from "./tokenProvider";

const API_URL = process.env.REACT_APP_API_URL || "";

const authProvider = {
  // authentication
  login: ({ username, password }) => {
    const request = new Request(`${API_URL}/api/v1/auth/login`, {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.message);
        }
        return response.json();
      })
      .then(({ data }) => {
        const { user, token, refreshToken } = data;
        localStorage.setItem("user", JSON.stringify(user));
        tokenProvider.setToken(token);
        tokenProvider.setRefreshToken(refreshToken);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  getPermissions: () => {
    return localStorage.getItem("user") !== null
      ? Promise.resolve(JSON.parse(localStorage.getItem("user")).role)
      : Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      tokenProvider.removeToken();
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => {
    return tokenProvider.getToken() ? Promise.resolve() : Promise.reject();
  },
  logout: () => {
    tokenProvider.removeToken();
    localStorage.removeItem("user");
    localStorage.removeItem("permissions");
    console.log("logout");
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { id, email, role } = JSON.parse(localStorage.getItem("user"));
      console.log("getIdentity", id, email, role);
      return Promise.resolve({ id, email, role });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // authorization
};

export default authProvider;
