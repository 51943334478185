// material-ui
import { Typography } from "@mui/material";
// project import
import LinearWithLabel from "./Progress/LinearWithLabel";
import PropTypes from "prop-types";
import { Loading } from "react-admin";
import { useEffect } from "react";
import { useDataProvider } from "react-admin";
import { useState } from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { fDateTime } from "../../utils/formatTime";
import { useGetList } from "react-admin";
import { Card, CardHeader, CardContent } from "@mui/material";
const LastTransactionWidget = ({ title, subheader, list, ...other }) => {
  const { data, total, isLoading, error } = useGetList("transactions", {
    pagination: { page: 1, perPage: 5 },
    sort: { order: "DESC" },
  });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  return (
    <Timeline>
      {data.map((item, index) => (
        <OrderItem
          key={item.id}
          item={item}
          isLast={index === data.length - 1}
        />
      ))}
    </Timeline>
  );
};

function OrderItem({ item, isLast }) {
  const { status, title, createdAt } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (status === "order1" && "primary") ||
            (status === "order2" && "success") ||
            (status === "pending" && "info") ||
            (status === "Canceled" && "warning") ||
            "error"
          }
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle2">{item.status}</Typography>
        <Typography variant="subtitle2">{item.campagneId}</Typography>
        <Typography variant="subtitle2">{title}</Typography>

        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          {fDateTime(createdAt)}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

LastTransactionWidget.propTypes = {
  tasks: PropTypes.array,
};

export default LastTransactionWidget;
