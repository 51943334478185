/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */

const API_URL = process.env.REACT_APP_API_URL || "";
const uploadFile = (params, field, name) => {
  return new Promise((resolve) => {
    if (params.data[name] && params.data[name].rawFile instanceof File) {
      const formData = new FormData();
      formData.append(field, params.data[name].rawFile);
      const token = localStorage.getItem("token");

      fetch(`${API_URL}/api/v1/uploads`, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((image) => {
          console.log(image);
          const tmp = {
            ...params,
            data: {
              ...params.data,
              [name]: {
                src: image.data.url,
                title: image.data.title,
              },
            },
          };
          return resolve(tmp);
        });
    } else {
      return resolve(params);
    }
  });
};

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = (requestHandler) => (type, resource, params) => {
  // console.log("==========addUploadFeature===========", type, resource, params);
  if ((type === "UPDATE" || type === "CREATE") && resource === "my") {
    return uploadFile(params, "image", "avatar")
      .then((params) => requestHandler(type, resource, params))
      .catch((error) => {
        throw new Error(error.message);
      });
  } else if (
    (type === "UPDATE" || type === "CREATE") &&
    resource === "campagnes"
  ) {
    // console.log(
    //   "==========addUploadFeature===========",
    //   type,
    //   resource,
    //   params
    // );

    return uploadFile(params, "image", "pictures")
      .then((params) => requestHandler(type, resource, params))
      .catch((error) => {
        throw new Error(error.message);
      });
    // for other request types and resources, fall back to the default request handler
  } else {
    return requestHandler(type, resource, params);
  }
};

export default addUploadFeature;
