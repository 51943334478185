import React from "react";
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const DeviceEdit = (props) => (
  <MainCard>
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="deviceName" fullWidth={true} />
        {/* <TextInput source="pairingCode" fullWidth={true} /> */}
        {/* <ReferenceInput source="user_ids" reference="users">
          <SelectInput optionText="email" />
        </ReferenceInput> */}
      </SimpleForm>
    </Edit>
  </MainCard>
);

export default DeviceEdit;
