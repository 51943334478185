import React from "react";

import {
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
} from "react-admin";
import { useRedirect } from "react-admin";
import { useNotify } from "react-admin";
import { Card, Box, Grid, Typography } from "@mui/material";
import tokenProvider from "../dataProvider/tokenProvider";
export const VerifyCode = () => {
  const redirect = useRedirect();
  const notify = useNotify();

  const dataProvider = useDataProvider();
  const handleSave = (values) => {
    dataProvider("verify-code", "auth", values).then((data) => {
      if (data.success === "failed") {
        notify(data.error.message, { type: "failed" });
      } else {
        const result = data.data;

        localStorage.setItem("user", JSON.stringify(result.user));
        tokenProvider.setToken(result.token);
        redirect("/my-changepassword");
      }
    });
  };

  const MyToolbar = () => {
    return (
      <Toolbar>
        <SaveButton label="Verify" icon={null} />
      </Toolbar>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "flex-start",
          background: "url(https://source.unsplash.com/random/1600x900)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Card sx={{ minWidth: 440, marginTop: "6em" }}>
          <SimpleForm
            redirect="list"
            onSubmit={handleSave}
            toolbar={<MyToolbar />}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">Enter Verification Code</Typography>
                <Typography color="secondary">We send you on mail.</Typography>
                <Typography>
                  We`ve send you code on jone. ****@company.com
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextInput source="email" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextInput source="code" fullWidth />
              </Grid>
            </Grid>
          </SimpleForm>
        </Card>
      </Box>
    </>
  );
};

export default VerifyCode;
