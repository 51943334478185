import React from "react";
import {
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
} from "react-admin";
import { Grid } from "@mui/material";

import MainCard from "../../pages/components/MainCard";
const role = [
  { id: "pending", name: "Pending" },
  { id: "passed", name: "Passed" },
  { id: "failed", name: "Failed" },
];
const TransactionEdit = (props) => {
  const { permissions } = usePermissions();

  return (
    <MainCard>
      <Edit {...props}>
        <SimpleForm redirect="list">
          <SelectInput source="status" choices={role} />
          {permissions === "admin" && (
            <Grid item xs={12}>
              <ReferenceInput
                fullWidth={true}
                source="user_ids"
                reference="users"
                sort={{ field: "email", order: "ASC" }}
              >
                <SelectInput optionText="email" fullWidth />
              </ReferenceInput>{" "}
            </Grid>
          )}
          <TextInput source="location" />
          <TextInput source="transactionId" />
          <NumberInput source="amount" />
          <ReferenceInput source="campagneId" reference="campagnes">
            <SelectInput optionText="title" />
          </ReferenceInput>
          <DateInput source="transactionDate" />
          <ReferenceInput source="device_ids" reference="devices">
            <SelectInput optionText="devideName" />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </MainCard>
  );
};
export default TransactionEdit;
