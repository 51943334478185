import React from "react";
import {
  Create,
  PasswordInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import MainCard from "../../pages/components/MainCard";

const role = [
  { id: "admin", name: "Admin" },
  { id: "user", name: "User" },
];
const UserCreate = (props) => (
  <MainCard>
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="lastname" />
        <TextInput source="username" />
        <TextInput source="street" />
        <TextInput source="city" />
        <TextInput source="postcode" />
        <TextInput source="email" />
        <PasswordInput source="password" />
        <SelectInput source="role" choices={role} />
      </SimpleForm>
    </Create>
  </MainCard>
);

export default UserCreate;
