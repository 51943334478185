import React from "react";
import { SimpleShowLayout, TextField, Show, ReferenceField } from "react-admin";
import MainCard from "../../pages/components/MainCard";

const BankShow = (props) => (
  <MainCard>
    <Show {...props}>
      <SimpleShowLayout>
        <ReferenceField source="user_id" reference="users">
          <TextField source="email" />
        </ReferenceField>

        <TextField source="owner" multiline fullWidth={true} />

        <TextField source="IBAN" multiline fullWidth={true} />
        <TextField source="SWIFT" multiline fullWidth={true} />
      </SimpleShowLayout>
    </Show>
  </MainCard>
);

export default BankShow;
